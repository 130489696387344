<template>
  <div
    class="nim-modal-background"
    :class="{ 'show': show, 'top' : top, 'drawer-mode': isDrawer }"
    @touchstart="startDrag"
    @touchmove="onDrag"
    @touchend="endDrag"
  >
    <div
      class="nim-modal text-platform-text-color overflow-x-hidden"
      :class="[{'show': show, 'wide': wide, 'half': half, 'ultra-wide': ultraWide, 'simple': simple}, 'modal-' + mode]"
      :style="[maxPixels ? { maxWidth: maxPixels + 'px', width: '100%' } : '', modalStyle]"
    >
      <div
        v-if="!simple"
        id="modal-top"
        class="nim-modal-title flex"
        :class="{ 'drawer-header': isDrawer }"
      >
        <h3 class="m-0 font-normal grow text-left">
          <slot name="title" />
        </h3>
        <div
          v-if="showCross"
          class="shrink my-auto"
        >
          <i
            class="fas fa-times cursor-pointer text-black"
            @click="closeFromCross"
          />
        </div>
      </div>

      <div
        class="leading-snug"
        :class="{ 'simple': simple, 'nim-modal-body': marginBody, 'drawer-body': isDrawer }"
      >
        <slot />
      </div>

      <div
        v-if="showFooter && !simple"
        class="nim-modal-footer flex items-center justify-center space-x-3"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'info'
    },
    wide: {
      type: Boolean,
      default: false
    },
    websiteModal: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    half: {
      type: Boolean,
      default: false
    },
    ultraWide: {
      type: Boolean,
      default: false
    },
    showCross: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: false
    },
    maxPixels: {
      type: [String, Number],
      required: false,
    },
    marginBody: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      isDrawer: false,
      startY: 0,
      currentY: 0,
      isDragging: false,
      translateY: 0
    };
  },
  computed: {
    modalStyle() {
      if (this.isDrawer) {
        return {
          transform: `translateY(${this.translateY}px)`,
          maxHeight: '75vh',
          width: '100%',
          borderRadius: '16px 16px 0 0',
          bottom: 0,
          position: 'fixed',
          transition: 'transform 0.3s'
        };
      }
    }
  },
  created() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);

    document.addEventListener('click', (event) => {
      if (
        this.show &&
        event.target &&
        typeof event.target.className === 'string' &&
        event.target.className.includes('nim-modal-background')
      ) {
        this.$emit('clickoff-modal');
        this.$emit('close-modal');
      }
    });

    document.addEventListener('keydown', (event) => {
      if (this.show && event.key === 'Escape') {
        this.$emit('escape-modal');
        this.$emit('close-modal');
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    closeFromCross() {
      this.$emit('close-modal');
      this.$emit('clicked-cross');
    },
    checkScreenSize() {
      this.isDrawer = window.innerWidth <= 768;
    },
    startDrag(event) {
      if (this.isDrawer) {
        this.isDragging = true;
        this.startY = event.touches[0].clientY;
      }
    },
    onDrag(event) {
      if (this.isDragging) {
        this.currentY = event.touches[0].clientY;
        const deltaY = this.currentY - this.startY;
        if (deltaY > 0) this.translateY = deltaY;
      }
    },
    endDrag() {
      if (this.isDragging) {
        this.isDragging = false;
        if (this.translateY > 300) {
          this.closeFromCross();
          this.translateY = 0;
        } else {
          this.translateY = 0;
        }
      }
    }
  }
};
</script>

<style>
.nim-modal {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
}
.drawer-body {
    overflow-y: auto;
    flex-grow: 1;
    max-height: 100%;
    padding: 16px;
}

</style>